import stations from '../data/stations.json'

const MAX_SUGGESTIONS_LIMIT = 20

// 候補順を制御するための比較関数
const compareSuggestOrder = word => {
  return (a, b) => {
    // 完全にマッチするものは最優先
    if (word === a.ja || word === a.jaHiragana || word === a.en) {
      return -1
    }
    if (word === b.ja || word === b.jaHiragana || word === b.en) {
      return 1
    }

    // 先頭がマッチするものは優先
    if (
      a.ja.startsWith(word) ||
      a.jaHiragana.startsWith(word) ||
      a.en.toLowerCase().startsWith(word.toLowerCase())
    ) {
      return -1
    }
    if (
      b.ja.startsWith(word) ||
      b.jaHiragana.startsWith(word) ||
      b.en.toLowerCase().startsWith(word.toLowerCase())
    ) {
      return 1
    }

    // 入力値が英数字で構成されていた場合、英語を基準にソート
    if (/[a-zA-Z0-9 ]+/.test(word)) {
      const array1 = [b.en.toLowerCase(), a.en.toLowerCase()]
      // 実際に辞書順でソートさせて a が前なら優先
      if (array1.sort().indexOf(array1[1]) === 0) {
        return -1
      }
      if (array1.sort().indexOf(array1[0]) === 0) {
        return 1
      }
      return 0
    }

    // そうでない場合、日本語を基準にソート
    const array2 = [b.ja.toLowerCase(), a.ja.toLowerCase()]
    if (array2.sort().indexOf(array2[1]) === 0) {
      return -1
    }
    if (array2.sort().indexOf(array2[0]) === 0) {
      return 1
    }
    return 0
  }
}

// 現在地、目的地の候補を推測しリストで返す
const suggestStations = (word, limit = MAX_SUGGESTIONS_LIMIT) => {
  if (word === '') {
    return []
  }

  return stations
    .filter(station => {
      if (!('candidates' in station)) {
        station.candidates = []
      }

      return (
        station.ja.includes(word) ||
        station.jaHiragana.includes(word) ||
        station.en.toLowerCase().includes(word.toLowerCase()) ||
        station.candidates.some(candidate => candidate.includes(word))
      )
    })
    .sort(compareSuggestOrder(word))
    .slice(0, limit)
}

export { suggestStations }
