import React from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'

import styles from './PageContents.module.css'

interface PageContentsProps {
  children: React.ReactNode
}

const PageContents: React.FC<PageContentsProps> = props => {
  const { t } = useTranslation()

  return (
    <DocumentTitle title={t('meta.title')}>
      <div className={styles.pageContents}>{props.children}</div>
    </DocumentTitle>
  )
}

export default PageContents
