import coursesRawObj from '../data/courses.json'

// 路線IDの連想配列になっているので、中身だけの配列形式にしておく
const coursesRawList = Object.values(coursesRawObj)

// 系統ごとの停留所リストから有効なパスを返す
const getRoutePath = (routeStations, originInput, destinationInput) =>
  routeStations.reduce((currentPath, { ja, en }, originIndex) => {
    if (ja !== originInput && en.toLowerCase() !== originInput.toLowerCase()) {
      return currentPath
    }
    const destinationIndex = routeStations.findIndex(
      (item, index) =>
        originIndex < index &&
        (item.ja === destinationInput ||
          item.en.toLowerCase() === destinationInput.toLowerCase()),
    )
    if (destinationIndex === -1 || originIndex >= destinationIndex) {
      return currentPath
    }

    const totalStations = destinationIndex - originIndex
    if (!currentPath || currentPath.totalStations > totalStations) {
      return {
        totalStations,
        originStationId: routeStations[originIndex].id,
        destinationStationId: routeStations[destinationIndex].id,
      }
    }

    return currentPath
  }, null)

// 路線オブジェクトをもとに系統ごとの最短パスをリストで返す
const getRoutePaths = ({ routes, stations }, originInput, destinationInput) => {
  return routes.reduce((routePaths, { id: routeId, stations: stationIds }) => {
    const routeStations = stationIds.map(id => stations.find(a => a.id === id))
    const path = getRoutePath(routeStations, originInput, destinationInput)
    if (!path) {
      return routePaths
    }

    return routePaths.concat({ ...path, routeId })
  }, [])
}

// 到達可能な系統を駅リストと駅名から見つける
const searchPossibleCourses = (originInput, destinationInput) => {
  return coursesRawList
    .reduce((possibleCourses, course) => {
      const { courseId, courseNo, routes } = course
      const paths = getRoutePaths(course, originInput, destinationInput)

      if (paths.length === 0) {
        return possibleCourses
      }
      const certain = routes.length === paths.length

      return possibleCourses.concat({
        paths,
        courseId,
        courseNo,
        certain,
      })
    }, [])
    .sort((a, b) => parseInt(a.courseNo, 10) - parseInt(b.courseNo, 10))
}

// 路線 ID から路線情報を返す
const getCourseById = courseId =>
  coursesRawList.find(course => course.courseId === courseId)

// 駅の通り順の組み合わせごとに情報提供する
const getCombinationPaths = (courseId, originInput, destinationInput) => {
  if (!(courseId in coursesRawObj)) {
    return []
  }

  const course = coursesRawObj[courseId]
  const { routes, stations: fullStations } = course
  const paths = getRoutePaths(course, originInput, destinationInput)

  return paths
    .reduce((combinations, path) => {
      const { routeId, originStationId, destinationStationId } = path
      const route = routes.find(route => route.id === routeId)
      if (!route) {
        console.error('not found route', routeId)
        return combinations
      }

      const { stations: stationIds } = route
      const originIndex = stationIds.findIndex(a => a === originStationId)
      const destinationIndex = stationIds.findIndex(
        a => a === destinationStationId,
      )
      const selectedStationIds = stationIds.filter((_, index) => {
        return originIndex <= index && index <= destinationIndex
      })

      const stationString = selectedStationIds.join('/')
      const index = combinations.findIndex(
        a => a.stations.join('/') === stationString,
      )
      if (index !== -1) {
        // すでにあるパスに系統情報のみ追加する
        combinations[index].routes.push({
          id: route.id,
          ja: route.ja,
        })
      } else {
        // 今までにないパスを追加する
        combinations.push({
          stations: selectedStationIds,
          routes: [
            {
              id: route.id,
              ja: route.ja,
            },
          ],
        })
      }

      return combinations
    }, [])
    .map(combination => {
      const stations = combination.stations.map(stationId =>
        fullStations.find(station => station.id === stationId),
      )
      return {
        ...combination,
        stations,
      }
    })
    .sort((a, b) => a.stations.length - b.stations.length)
}

// 駅の通り方を基準にして、通り方ごとの情報を提供する
export {
  getRoutePath,
  getRoutePaths,
  searchPossibleCourses,
  getCourseById,
  getCombinationPaths,
}
