import i18n from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'

const resources = {
  ja: {
    busnchu: {
      'words.title': 'ばすんちゅアプリ',
      'words.description': '〜沖縄のバスを乗りこなそう〜',
      'words.origin': '出発地',
      'words.destination': '目的地',
      'words.submitlabel': '路線を探す',
      'words.aboarding': '乗る場所',
      'words.alighting': '降りる場所',
      'words.contactform': 'お問い合わせフォーム',
      'words.busmapokinawa': 'バスマップ沖縄',

      'meta.title': '$t(words.title) $t(words.description)',

      'header.title': '$t(words.title)',
      'header.description': '$t(words.description)',

      'introduction.description': '困ったら<br />僕に聞いてね！',

      'searchform.originplaceholder': '$t(words.origin)を入力してね',
      'searchform.destinationplaceholder': '$t(words.destination)を入力してね',
      'searchform.completion': '入力すると候補が表示されるよ！',
      'searchform.candidatetext': '{{count}} 件候補があるよ！',
      'searchform.submit': '$t(words.submitlabel)',

      'courseresults.certaintext': 'この番号なら確実に到着するよ！',
      'courseresults.uncertaintext': 'この番号も目的地に到着するかも？',
      'courseresults.aboarding': '$t(words.aboarding)：',
      'courseresults.alighting': '$t(words.alighting)：',
      'courseresults.totalstations': '駅数：',
      'courseresults.linkofficial':
        '時刻表・接近情報が見たい！<br />（公式ページへ）',
      'courseresults.pathnumber': 'ルート{{count}}',
      'courseresults.stationlist': '駅名：',
      'courseresults.mismatchtext':
        '$t(words.origin)や$t(words.destination)が<br />合っているか確認してみてね！',
      'courseresults.foundtext': '{{count}}つの路線が見つかったよ！',
      'courseresults.notfoundtext': '路線が見つかりませんでした・・・',
      'courseresults.backbuttonlabel': '別の路線を探す',

      'settings.scraping.heading': 'データ取得日',
      'settings.disclaimer.heading': '免責事項',
      'settings.disclaimer.text1': '必ずしも正しさを保証するものではないよ。',
      'settings.disclaimer.text2':
        'バスが来なかったとしても責任を追うものではないよ、むしろアップデートに協力してね！',
      'settings.disclaimer.text3':
        '実際の表記・標識・交通ルール・アナウンスに従ってね。',
      'settings.contact.heading': '問い合わせ方法',
      'settings.contact.text': '<0>$t(words.contactform)</0>から投稿してね！',
      'settings.contact.link': 'https://form.run/@busnchu-ja',
      'settings.rights.heading': '帰属先',
      'settings.rights.text1':
        '$t(words.busmapokinawa)と協力して提供しているよ！時刻表や路線図を俯瞰して見たいときはこっちを見てね！',
      'settings.rights.text2':
        '$t(words.title)の問い合わせについては、$t(words.busmapokinawa)へ投稿せずに<0>$t(words.contactform)</0>へ投稿してね！',
      'settings.busmaplinktext': '$t(words.busmapokinawa)のページへ',

      'contactform.soon': '準備中だよ',

      'menu.courses': '路線',
      'menu.soon': 'Soon',
      'menu.settings': '設定',

      'help.isee': 'なるほど！',
      'help.introduction':
        '沖縄本島のバス、たくさんあってよく分からないけど、このアプリを使うとどの番号に乗ればいいかすぐに分かるよ！<br /><br />今は <strong>現在地と目的地のバス停をすでに知っている前提</strong>のサービスだよ。<br />そのうち現在地や目的地のバス停が分からないケースも対応していくから、長い目で見守っていてね！',
      'help.origin':
        '乗りたいバス停の名前を入力してね！<br>途中かけでも候補が出るから、そこから選んでも大丈夫だよ！',
      'help.destination':
        '降りたいバス停の名前を入力してね！<br>途中かけでも候補が出るから、そこから選んでも大丈夫だよ！',
      'help.certain':
        '同じ番号のうち、ルート（系統・バス停の通る順序）が1種類しかないか、複数あっても必ず$t(words.origin)・$t(words.destination)を通る番号だよ！',
      'help.uncertain':
        '同じ番号のうち、ルート（系統・バス停の通る順序）が複数あって、ルートによっては$t(words.destination)の方に行かないものもあるよ！もし不安なら運転手さんに聞いてみるのもいいね！',
    },
  },
  en: {
    busnchu: {
      'words.title': 'Busnchu App',
      'words.description': '- Okinawan Bus Search -',
      'words.origin': 'Origin',
      'words.destination': 'Destination',
      'words.submitlabel': 'Search',
      'words.aboarding': '$t(words.origin) station',
      'words.alighting': '$t(words.destination) station',
      'words.contactform': 'Contact Form',
      'words.busmapokinawa': 'Busmap OKINAWA',

      'meta.title': '$t(words.title) $t(words.description)',

      'header.title': '$t(words.title)',
      'header.description': '$t(words.description)',

      'introduction.description': 'Need a help?<br />Please ask me!',

      'searchform.originplaceholder': '$t(words.origin) station here',
      'searchform.destinationplaceholder': '$t(words.destination) station here',
      'searchform.completion': 'If typing, You can see candidates.',
      'searchform.candidatetext': '{{count}} candidate found!',
      'searchform.candidatetext_plural': '{{count}} candidates found!',
      'searchform.submit': '$t(words.submitlabel)',

      'courseresults.certaintext': 'Always arrive number',
      'courseresults.uncertaintext': 'Not always arrive number',
      'courseresults.aboarding': '$t(words.aboarding): ',
      'courseresults.alighting': '$t(words.alighting): ',
      'courseresults.totalstations': 'total stations: ',
      'courseresults.linkofficial':
        'Timetable, Bus approaching info<br />(Official)',
      'courseresults.pathnumber': 'pattern {{count}}',
      'courseresults.stationlist': 'station list: ',
      'courseresults.mismatchtext':
        'Please check again $t(words.origin), $t(words.destination).',
      'courseresults.foundtext': '{{count}} course found!',
      'courseresults.foundtext_plural': '{{count}} courses found!',
      'courseresults.notfoundtext': 'courses not found...',
      'courseresults.backbuttonlabel': 'Search again',

      'settings.scraping.heading': 'Update Date',
      'settings.disclaimer.heading': 'Disclaimer',
      'settings.disclaimer.text1':
        'This service does not necessarily guarantee correctness.',
      'settings.disclaimer.text2':
        " We are not responsible, even if the bus does not come. Let's update with us!",
      'settings.disclaimer.text3':
        'Follow the actual signs, traffic rules and announcements.',
      'settings.contact.heading': 'How to contact',
      'settings.contact.text': '<0>$t(words.contactform)</0>',
      'settings.contact.link': 'https://form.run/@busnchu-en',
      'settings.rights.heading': 'Rights',
      'settings.rights.text1':
        'Working with $t(words.busmapokinawa). Please see this when you want to see the timetable and route map.',
      'settings.rights.text2':
        'Ask $t(words.title) (<0>$t(words.contactform)</0>) not $t(words.busmapokinawa) but $t(words.title)',
      'settings.busmaplinktext': 'Go to $t(words.busmapokinawa)',

      'contactform.soon': 'Soon...',

      'menu.courses': 'Courses',
      'menu.soon': 'Soon',
      'menu.settings': 'Settings',

      'help.isee': 'I got it!',
      'help.introduction':
        'I do not understand Okinawa bus too complicated...!<br /><br />But this app will tell me about Bus course number.<br />(As long as you know the place of departure and the destination.)<br />You will be fun to get on the bus. Have a great journey!',
      'help.origin':
        "Let's type $t(words.origin) station, You can also select station candidates.",
      'help.destination':
        "Let's type $t(words.destination) station, You can also select station candidates.",
      'help.certain':
        'You can always reach your $t(words.destination). Just get on the bus with that number.',
      'help.uncertain':
        "You do not always reach your destination. Because there are multiple routes...<br />Let's ask the driver if you are in trouble!",
    },
  },
}

i18n
  .use(initReactI18next)
  .use(I18nextProvider)
  .init({
    resources,
    lng: 'ja',
    fallbackLng: 'ja',
    keySeparator: false,
    ns: ['busnchu'],
    defaultNS: 'busnchu',
    interpolation: {
      escapeValue: false,
    },
    react: {
      transEmptyNodeValue: 'test',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong'],
    },
    debug: false,
  })

export default i18n
