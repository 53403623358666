import React from 'react'
import { Trans } from 'react-i18next'
import styles from './CourseResults.module.css'

import CourseResultsMatched from './CourseResultsMatched'
import CourseResultsMismatched from './CourseResultsMismatched'

interface CourseResultProps {
  isPanelOpen: boolean
  originInput: string
  destinationInput: string
  searchResults: Array<any>
  switchTogglePanel: (event: any) => void
}

const CourseResults: React.FC<CourseResultProps> = props => {
  const {
    isPanelOpen,
    originInput,
    destinationInput,
    searchResults,
    switchTogglePanel,
  } = props
  const hasResults = searchResults.length > 0

  return (
    <div
      className={
        isPanelOpen ? styles.courseResultsOpened : styles.courseResultsClosed
      }
    >
      <div className={styles.courseResultsInner}>
        <div className={styles.courseResultsPanel}>
          <h2 className={styles.courseResultsHeading}>
            {hasResults ? (
              <Trans
                i18nKey="courseresults.foundtext"
                count={searchResults.length}
              >
                foundtext
              </Trans>
            ) : (
              <Trans i18nKey="courseresults.notfoundtext">notfoundtext</Trans>
            )}
          </h2>
          <div className={styles.courseResultsContents}>
            {hasResults ? (
              <CourseResultsMatched
                originInput={originInput}
                destinationInput={destinationInput}
                searchResults={searchResults}
              />
            ) : (
              <CourseResultsMismatched />
            )}
            <button
              tabIndex={hasResults ? 0 : -1}
              className={styles.courseResultsTogglebutton}
              onClick={switchTogglePanel}
            >
              <Trans i18nKey="courseresults.backbuttonlabel">
                backbuttonlabel
              </Trans>
            </button>
          </div>
          <span
            className={styles.courseResultsClosebutton}
            onClick={switchTogglePanel}
          >
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>
    </div>
  )
}

export default CourseResults
