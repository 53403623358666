import React from 'react'
import { Trans } from 'react-i18next'
import styles from './CourseResultsMismatched.module.css'

const CourseResultsMismatched: React.FC = () => (
  <>
    <div className={styles.mismatchedMessage}>
      <img
        className={styles.mismatchedMessageIcon}
        src="/resources/bus.png"
        width="100"
        alt=""
      />
      <p className={styles.mismatchedMessageText}>
        <Trans i18nKey="courseresults.mismatchtext">mismatchtext</Trans>
      </p>
    </div>
  </>
)

export default CourseResultsMismatched
