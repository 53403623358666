import React, { useState } from 'react'

import SearchForm from './SearchForm'
import CourseResults from './CourseResults'

import * as stationsService from '../services/stations'
import * as coursesService from '../services/courses'

interface Candidate {
  ja: string
  jaHiragana: string
  en: string
}
interface CourseComparable {
  certain: boolean
  totalStations: number
  courseNo: string
}

const Courses: React.FC = () => {
  const [originInput, setOriginInput] = useState('')
  const [originCandidates, setOriginCandidates] = useState<Candidate[]>([])
  const [destinationInput, setDestinationInput] = useState('')
  const [destinationCandidates, setDestinationCandidates] = useState<
    Candidate[]
  >([])
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [searchResults, setSearchResults] = useState<Array<any>>([])

  const changeOriginHandler = (newOriginInput: string) => {
    const newOriginCandidates = stationsService.suggestStations(newOriginInput)

    setOriginInput(newOriginInput)
    setOriginCandidates(newOriginCandidates)
  }

  const changeDestinationHandler = (newDestinationInput: string) => {
    const newDestinationCandidates = stationsService.suggestStations(
      newDestinationInput,
    )

    setDestinationInput(newDestinationInput)
    setDestinationCandidates(newDestinationCandidates)
  }

  const submitHandler = () => {
    const searchResults = coursesService
      .searchPossibleCourses(originInput, destinationInput)
      .map(
        ({
          courseId,
          certain,
          paths,
        }: {
          courseId: string
          certain: boolean
          paths: Array<any>
        }) => {
          const course = coursesService.getCourseById(courseId)
          const combinations = coursesService.getCombinationPaths(
            courseId,
            originInput,
            destinationInput,
          )
          const totalStations = Math.min(
            ...paths.map((path: any) => path.totalStations),
          )

          return {
            ...course,
            certain,
            paths,
            combinations,
            totalStations,
          }
        },
      )
      .sort((a: any, b: any) => {
        // TODO: type matching
        // 駅数の少ない順に表示しつつ、同じだった場合は路線番号の小さい順にする
        if (a.totalStations < b.totalStations) {
          return -1
        }
        if (a.totalStations > b.totalStations) {
          return 1
        }
        return parseInt(a.courseNo, 10) < parseInt(b.courseNo, 10) ? 1 : -1
      })

    setSearchResults(searchResults)
    setIsPanelOpen(true)
  }

  const switchTogglePanel = () => {
    if (isPanelOpen) {
      setSearchResults([])
    }
    setIsPanelOpen(!isPanelOpen)
  }

  return (
    <>
      <SearchForm
        originInput={originInput}
        destinationInput={destinationInput}
        originCandidates={originCandidates}
        destinationCandidates={destinationCandidates}
        changeOriginHandler={changeOriginHandler}
        changeDestinationHandler={changeDestinationHandler}
        submitHandler={submitHandler}
      />
      {isPanelOpen ? (
        <CourseResults
          originInput={originInput}
          destinationInput={destinationInput}
          searchResults={searchResults}
          isPanelOpen={isPanelOpen}
          switchTogglePanel={switchTogglePanel}
        />
      ) : null}
    </>
  )
}

export default Courses
