import React, { useState, useRef, useEffect } from 'react'
import styles from './Tips.module.css'
import tipsRaw from '../data/tips.json'

const STOPPING_DURATION = 2000

const Tips: React.FC = () => {
  const tips = tipsRaw.map((item, index) => ({ item, index }))

  const [tipsIndex, setTipsIndex] = useState(0)
  const [flagScroll, setFlagScroll] = useState(false)
  const tipsIndexCounter = useRef(tipsIndex)
  const tipsRef = useRef<HTMLDivElement>(null)
  const tipsItemRef = useRef<HTMLDivElement>(null)

  const firstTips = tips[tipsIndex]
  const secondTips = tips[(tipsIndex + 1) % tips.length]

  const getTipsElementWidth = () => {
    const element = tipsItemRef.current

    if (!element) {
      return 0
    }

    return element.getBoundingClientRect().width
  }
  const getDuration = () => {
    return getTipsElementWidth() * 10
  }

  const addDynamicStyle = () => {
    const duration = getDuration()
    const width = getTipsElementWidth()

    return flagScroll
      ? {
          transitionDuration: `${duration}ms`,
          marginLeft: `-${width}px`,
        }
      : {}
  }

  useEffect(() => {
    tipsIndexCounter.current = tipsIndex
    setFlagScroll(false)
  }, [tipsIndex])

  useEffect(() => {
    const duration = getDuration()

    setTimeout(() => {
      setFlagScroll(true)
    }, STOPPING_DURATION)

    setTimeout(() => {
      setTipsIndex((tipsIndex + 1) % tips.length)
    }, STOPPING_DURATION + duration)
  })

  return (
    <div className={styles.tips} id="tips" ref={tipsRef}>
      <div className={styles.tipsInner}>
        <div className={styles.tipsDisplay}>
          <div
            ref={tipsItemRef}
            className={[styles.tipsItem, styles.tipsItemFirst].join(' ')}
            style={addDynamicStyle()}
          >
            {firstTips.item}
          </div>
          <div className={[styles.tipsItem, styles.tipsItemSecond].join(' ')}>
            {secondTips.item}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tips
