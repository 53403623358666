import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import styles from './Settings.module.css'

const Settings: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.settings}>
      <div className={styles.settingsInner}>
        <h2>
          <Trans i18nKey="settings.scraping.heading">scraping.heading</Trans>
        </h2>
        <p>2019/10</p>

        <h2>
          <Trans i18nKey="settings.disclaimer.heading">
            disclaimer.heading
          </Trans>
        </h2>
        <p>
          <Trans i18nKey="settings.disclaimer.text1">disclaimer.text1</Trans>
        </p>
        <p>
          <Trans i18nKey="settings.disclaimer.text2">disclaimer.text2</Trans>
        </p>
        <p>
          <Trans i18nKey="settings.disclaimer.text3">disclaimer.text3</Trans>
        </p>

        <h2>
          <Trans i18nKey="settings.contact.heading">contact.heading</Trans>
        </h2>
        <p>
          <Trans i18nKey="settings.contact.text">
            <a
              href={t('settings.contact.link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              contact.text
            </a>
          </Trans>
        </p>

        <h2>
          <Trans i18nKey="settings.rights.heading">rights.heading</Trans>
        </h2>
        <p>
          <Trans i18nKey="settings.rights.text1">rights.text1</Trans>
        </p>
        <p>
          <Trans i18nKey="settings.rights.text2">
            <a
              href={t('settings.contact.link')}
              target="_blank"
              rel="noopener noreferrer"
            >
              rights.text2
            </a>
          </Trans>
        </p>

        <hr />
        <p>
          <a
            href="http://www.kotsu-okinawa.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans i18nKey="settings.busmaplinktext">busmaplinktext</Trans>
          </a>
        </p>
      </div>
    </div>
  )
}

export default Settings
