import React from 'react'
import { Trans } from 'react-i18next'
import i18n from 'i18next'

import styles from './Header.module.css'

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.headerDemo}>
          <span className={styles.headerDemoText}>
            デモ用の古い
            <br />
            バージョンです
          </span>
        </div>
        <h1 className={styles.headerHeading}>
          <span className={styles.headerHeadingTitle}>
            <Trans i18nKey="header.title">title</Trans>
          </span>
          <span className={styles.headerHeadingDescription}>
            <Trans i18nKey="header.description">description</Trans>
          </span>
        </h1>
        <a
          className={styles.headerLang}
          href={i18n.language === 'en' ? '/' : '/?lang=en'}
        >
          <img
            className={styles.headerLangIcon}
            src="/resources/lang.png"
            alt=""
          />
        </a>
      </div>
    </header>
  )
}

export default Header
