import React from 'react'
import { Trans } from 'react-i18next'

import styles from './SearchForm.module.css'
import OriginFormElement from './OriginFormElement'
import DestinationFormElement from './DestinationFormElement'
import Introduction from './Introduction'

interface Candidate {
  ja: string
  jaHiragana: string
  en: string
}
interface ReplaceProps {
  originInput: string
  destinationInput: string
  changeOriginHandler: (input: string, isAuto: boolean) => void
  changeDestinationHandler: (input: string, isAuto: boolean) => void
}
interface SearchFormProps {
  originInput: string
  destinationInput: string
  originCandidates: Candidate[]
  destinationCandidates: Candidate[]
  changeOriginHandler: (input: string) => void
  changeDestinationHandler: (input: string) => void
  submitHandler: (event: React.MouseEvent) => void
}

const ReplaceButton: React.FC<ReplaceProps> = props => {
  const {
    originInput,
    destinationInput,
    changeOriginHandler,
    changeDestinationHandler,
  } = props

  const replaceHandler = () => {
    changeOriginHandler(destinationInput, false)
    changeDestinationHandler(originInput, false)
  }

  const isReplaceDisabled = () => {
    return originInput === '' && destinationInput === ''
  }

  return (
    <button
      tabIndex={-1}
      className={styles.replaceButton}
      onClick={replaceHandler}
      disabled={isReplaceDisabled()}
    >
      <i className={[styles.replaceButtonIcon, 'fas fa-random'].join(' ')}></i>
    </button>
  )
}

const SearchForm: React.FC<SearchFormProps> = props => {
  const { originInput, destinationInput, submitHandler } = props

  const isSubmitDisabled = () => {
    return originInput === '' || destinationInput === ''
  }

  return (
    <div className={styles.container}>
      <div className={styles.inputArea}>
        <OriginFormElement {...props} />
        <DestinationFormElement {...props} />
        <ReplaceButton {...props} />
      </div>
      <Introduction />
      <div className={styles.submitArea}>
        <button
          className={styles.submitButton}
          onClick={submitHandler}
          disabled={isSubmitDisabled()}
        >
          <Trans i18nKey="searchform.submit">submit</Trans>
          <span className={styles.submitButtonIcon}>
            <i className="fas fa-bus-alt"></i>
          </span>
        </button>
      </div>
    </div>
  )
}

export default SearchForm
