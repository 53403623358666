import React from 'react'
import { NavLink } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { Trans } from 'react-i18next'

import styles from './Menu.module.css'

const Menu: React.FC = () => {
  const { location } = useReactRouter()

  return (
    <nav className={styles.menu}>
      <ul className={styles.menuList}>
        <li className={[styles.menuItem, styles.menuItemCourses].join(' ')}>
          <NavLink
            to={{ pathname: '/', search: location.search }}
            className={styles.menuItemInner}
            activeClassName="active"
          >
            <i className={[styles.menuItemIcon, 'fas fa-bus'].join(' ')}></i>
            <span className={styles.menuItemText}>
              <Trans i18nKey="menu.courses">courses</Trans>
            </span>
          </NavLink>
        </li>
        <li
          className={[
            styles.menuItem,
            styles.menuItemCommingSoon,
            styles.menuItemDisabled,
          ].join(' ')}
        >
          <div className={styles.menuItemInner}>
            <i
              className={[styles.menuItemIcon, 'fas fa-ellipsis-h'].join(' ')}
            ></i>
            <span className={styles.menuItemText}>
              <Trans i18nKey="menu.soon">soon</Trans>
            </span>
          </div>
        </li>
        <li className={[styles.menuItem, styles.menuItemSettings].join(' ')}>
          <NavLink
            to={{ pathname: '/settings', search: location.search }}
            className={styles.menuItemInner}
            activeClassName="active"
          >
            <i className={[styles.menuItemIcon, 'fas fa-cog'].join(' ')}></i>
            <span className={styles.menuItemText}>
              <Trans i18nKey="menu.settings">settings</Trans>
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Menu
