import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import styles from './Help.module.css'
import { CSSTransition } from 'react-transition-group'

interface HelpProps {
  i18nKey: string
}

const Help: React.FC<HelpProps> = props => {
  const { i18nKey } = props
  const [isShown, setIsShown] = useState(false)

  return (
    <>
      <i className={styles.help} onClick={() => setIsShown(true)}>
        ?
      </i>
      <CSSTransition
        in={isShown}
        timeout={{
          enter: 250,
          exit: 150,
        }}
        classNames={{
          enter: styles.helpModalEnter,
          enterActive: styles.helpModalEnterActive,
          enterDone: styles.helpModalEnterDone,
          exit: styles.helpModalExit,
          exitActive: styles.helpModalExitActive,
        }}
      >
        <div className={styles.helpModal} onClick={() => setIsShown(false)}>
          <div className={styles.helpModalInner}>
            <div className={styles.helpModalHeader}>
              <i className={styles.helpModalIcon}>!</i>
              <img
                className={styles.helpModalCharacter}
                src="/resources/bus.png"
                width="100"
                alt=""
              />
            </div>
            <p className={styles.helpModalText}>
              <Trans i18nKey={i18nKey}>help</Trans>
            </p>
            <div className={styles.helpModalButton}>
              <Trans i18nKey="help.isee">isee</Trans>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export default Help
