import React from 'react'

export const trimBusstopName = (fullname: string) => {
  if (!fullname) {
    return ''
  }
  return fullname.replace(/(?:\d+)?\s*(.*)\s*(（.*）)+?/, (match, p1) => p1)
}

export const timetableURL = (stationSid: string) => {
  const baseURL = 'https://www.busnavi-okinawa.com/top/ViewTimeTable'
  const params = {
    selectLang: 'ja',
    parentCompanyCode: 9000,
    stationSid,
  }

  return `${baseURL}?${Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')}`
}

const KEYCODE_TAB = 9
const KEYCODE_ENTER = 13
const KEYCODE_ESCAPE = 27
const KEYCODE_UP = 38
const KEYCODE_DOWN = 40
const KEYCODE_J = 74
const KEYCODE_K = 75
const KEYCODE_N = 78
const KEYCODE_P = 80

export const eventTabKeyDown = (e: React.KeyboardEvent) =>
  e.keyCode === KEYCODE_TAB

export const eventEnterKeyDown = (e: React.KeyboardEvent) =>
  e.keyCode === KEYCODE_ENTER

export const eventEscapeKeyDown = (e: React.KeyboardEvent) =>
  e.keyCode === KEYCODE_ESCAPE

export const eventPrevKeyDown = (e: React.KeyboardEvent) =>
  e.keyCode === KEYCODE_UP ||
  (e.ctrlKey && e.keyCode === KEYCODE_K) ||
  (e.ctrlKey && e.keyCode === KEYCODE_P)

export const eventNextKeyDown = (e: React.KeyboardEvent) =>
  e.keyCode === KEYCODE_DOWN ||
  (e.ctrlKey && e.keyCode === KEYCODE_J) ||
  (e.ctrlKey && e.keyCode === KEYCODE_N)
