import React from 'react'
// import { Trans, useTranslation } from 'react-i18next'

import s from './Hello.module.css'

const Hello: React.FC = () => {
  // const { t } = useTranslation()

  return (
    <div className={s.container}>
      <div className={s.heroArea}>
        <div className={s.heroAreaInner}>
          <div className={s.heroTitle}>
            <h1 className={s.heroTitleHeading}>
              <img
                className={s.heroTitleHeadingImage}
                src="/resources/bus.png"
                height="30"
                alt=""
              />
              <span className={s.heroTitleHeadingText}>ばすんちゅアプリ</span>
            </h1>
            <p className={s.heroTitleHeadingExtra}>
              〜沖縄のバスを乗りこなそう〜
            </p>
          </div>
          <div className={s.heroBlock}>
            <h2 className={s.heroCatch}>
              <span className={s.heroCatchFirst}>すぐ分かる！</span>
              <br />
              番号だけさっと教えてくれる
              <br />
              沖縄のバスアプリ誕生！
            </h2>
            <img
              className={s.heroImage}
              src="/resources/example.png"
              alt="ばすんちゅアプリのキャプチャイメージ"
            />
            <a href="/" className={s.heroNavigation}>
              つかってみる
            </a>
          </div>
        </div>
      </div>

      <div className={s.stepsArea}>
        <div className={s.stepsAreaInner}>
          <div className={[s.stepsBlock, s.stepsBlock1].join(' ')}>
            <span className={s.stepsNo}>1</span>
            <h3 className={s.stepsSubTitle}>
              今いるバス停と行きたいバス停の
              <br />
              2つを調べる！
            </h3>
            <img
              className={s.stepsImage}
              src="/resources/step1.png"
              alt="1.今いるバス停と行きたいバス停の2つを調べる！"
            />
            <p className={s.stepsDescription}>
              ○○経由とか、○○方面とか、沖縄の地名が全然分からなくても、
              <br />
              今いるバス停と行きたいバス停だけ分かれば使えるサービスだよ！
            </p>
          </div>
          <div className={[s.stepsBlock, s.stepsBlock2].join(' ')}>
            <span className={s.stepsNo}>2</span>
            <h3 className={s.stepsSubTitle}>今いるバス停を入力！</h3>
            <img
              className={s.stepsImage}
              src="/resources/step2.png"
              alt="2.今いるバス停を入力！"
            />
            <p className={s.stepsDescription}>
              一部しか分からなくても、補完してくれるから安心して入力してね！
            </p>
          </div>
          <div className={[s.stepsBlock, s.stepsBlock3].join(' ')}>
            <span className={s.stepsNo}>3</span>
            <h3 className={s.stepsSubTitle}>
              行きたいバス停を入力して、
              <br />
              路線を探すボタンを押す！
            </h3>
            <img
              className={s.stepsImage}
              src="/resources/step3.png"
              alt="3.行きたいバス停を入力して、路線を探すボタンを押す！"
            />
            <p className={s.stepsDescription}>
              今いるバス停と同じく行きたいバス停も入力して、
              <br />
              路線を探すボタンを押してね！
            </p>
          </div>
          <div className={[s.stepsBlock, s.stepsBlock4].join(' ')}>
            <span className={s.stepsNo}>4</span>
            <h3 className={s.stepsSubTitle}>結果を確認！</h3>
            <img
              className={s.stepsImage}
              src="/resources/step4.png"
              alt="4.結果を確認！"
            />
            <p className={s.stepsDescription}>
              上に必ず到着するバスの番号が表示されるよ！
              <br />
              下にもバスの詳しい情報が表示されるので、もっと知りたかったらそちらを見てね！
            </p>
          </div>
        </div>
      </div>

      <div className={s.detailsArea}>
        <div className={s.detailsAreaInner}>
          <h2 className={s.detailsTitle}>
            検索結果画面では、他にもこんなことが分かるよ！
          </h2>
          <div className={s.detailsBlock}>
            <img
              className={s.detailsImage}
              src="/resources/details.png"
              alt="検索結果画面"
            />
            <div className={s.detailsTextBox}>
              <ul className={s.detailsList}>
                <li className={s.detailsListItem}>
                  <i className="fas fa-check"></i>
                  <span className={s.detailsListItemText}>
                    路線の詳しい名前（知らなくても使えるよ！）
                  </span>
                </li>
                <li className={s.detailsListItem}>
                  <i className="fas fa-check"></i>
                  <span className={s.detailsListItemText}>最短の駅数</span>
                </li>
                <li className={s.detailsListItem}>
                  <i className="fas fa-check"></i>
                  <span className={s.detailsListItemText}>
                    公式の時刻表や接近情報に直接行けるリンク
                  </span>
                </li>
                <li className={s.detailsListItem}>
                  <i className="fas fa-check"></i>
                  <span className={s.detailsListItemText}>
                    ルートごとに通る駅の情報
                  </span>
                </li>
                <li className={s.detailsListItem}>
                  <i className="fas fa-check"></i>
                  <span className={s.detailsListItemText}>
                    バス豆情報（使ってるうちに知識が増えちゃうよ）
                  </span>
                </li>
                <li className={s.detailsListItem}>
                  <i className="fas fa-check"></i>
                  <span className={s.detailsListItemText}>ずっと無料！</span>
                </li>
              </ul>
              <div className={s.detailsMessage}>
                <img
                  className={s.detailsMessageIcon}
                  src="/resources/bus.png"
                  width="100"
                  alt=""
                />
                <p className={s.detailsMessageText}>
                  どんどん使いやすくアップデートしていくので応援してね！
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.landingArea}>
        <div className={s.landingAreaInner}>
          <h2 className={s.landingTitle}>
            日本語・英語の両方に対応してるので、
            <br />
            <span className={s.landingTitlePoint}>土地勘のない観光客</span>
            や日本語分からない
            <span className={s.landingTitlePoint}>外国人の方におすすめ！</span>
          </h2>
          <a href="/" className={s.landingNavigation}>
            試してみる！
          </a>
          <hr className={s.landingSeparator} />
          <div className={s.landingMessage}>
            <img
              className={s.landingMessageIcon}
              src="/resources/bus.png"
              width="100"
              alt=""
            />
            <p className={s.landingMessageText}>
              どのバスに乗っていいのか分からない人のために、
              <br />
              身の回りの人にシェアしてあげてね！
            </p>
          </div>
          <ul className={s.landingSNSList}>
            <li className={s.landingSNSListItem}>
              <a
                className={s.landingSNSButtonLINE}
                href="https://social-plugins.line.me/lineit/share?url=https%3A%2F%2Fbusnchu.com%2Fhello"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-line fa-3x"></i>
              </a>
            </li>
            <li className={s.landingSNSListItem}>
              <a
                className={s.landingSNSButtonTwitter}
                href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fbusnchu.com%2Fhello&text=
%E3%81%B0%E3%81%99%E3%82%93%E3%81%A1%E3%82%85%E3%82%A2%E3%83%97%E3%83%AA%20-%20%E6%B2%96%E7%B8%84%E3%81%AE%E3%83%90%E3%82%B9%E3%82%92%E4%B9%97%E3%82%8A%E3%81%93%E3%81%AA%E3%81%9D%E3%81%86
"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter-square fa-3x"></i>
              </a>
            </li>
            <li className={s.landingSNSListItem}>
              <a
                className={s.landingSNSButtonFacebook}
                href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fbusnchu.com%2Fhello"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-square fa-3x"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <footer className={s.footer}>
        <div className={s.footerInner}>
          <h2 className={s.footerTitle}>ばすんちゅアプリ</h2>
          <div className={s.footerColumns}>
            <div className={s.footerColumn}>
              <ul className={s.footerList}>
                <li className={s.footerItem}>
                  <a className={s.footerLink} href="/">
                    ばすんちゅアプリ 日本語版
                  </a>
                </li>
                <li className={s.footerItem}>
                  <a
                    className={s.footerLink}
                    href="https://form.run/@busnchu-ja"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    お問い合わせフォーム（日本語）
                  </a>
                </li>
              </ul>
            </div>
            <div className={s.footerColumn}>
              <ul className={s.footerList}>
                <li className={s.footerItem}>
                  <a className={s.footerLink} href="/?lang=en">
                    ばすんちゅアプリ 英語版
                  </a>
                </li>
                <li className={s.footerItem}>
                  <a
                    className={s.footerLink}
                    href="https://form.run/@busnchu-en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    お問い合わせフォーム（英語）
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={s.footerNotice}>
            <p className={s.footerNoticeText}>
              必ずしも提供した情報に関して正しさを保証するものではありません。
              <br />
              また、情報通りにバスが来なかったとしても責任を追うものではありません。
            </p>
            <p className={s.footerNoticeText}>
              実際の表記・標識・交通ルール・アナウンスに従ってください。
            </p>
            <p className={s.footerNoticeText}>
              このサービスは個人で開発しているものです。
              <br />
              苦情は受けつけておりませんが、
              使っていく上でより良く改善できそうなご意見・ご感想はお待ちしております。
              <br />
              上記お問い合わせフォームよりご意見いただけましたら幸いです。
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Hello
