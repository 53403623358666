import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'

import i18n from 'i18next'
import CandidatesList from './CandidatesList'
import styles from './DestinationFormElement.module.css'
import {
  eventTabKeyDown,
  eventEnterKeyDown,
  eventPrevKeyDown,
  eventNextKeyDown,
  eventEscapeKeyDown,
} from '../helpers'

interface Candidate {
  ja: string
  jaHiragana: string
  en: string
}
interface DestinationFormElementTextProps {
  destinationInput: string
  destinationCandidates: Candidate[]
  candidateIndex: number
  inputFormRef: React.RefObject<HTMLInputElement>
  changeDestinationHandler: (candidate: string) => void
  decisionHandler: () => void
  keyDownHandler: (event: React.KeyboardEvent) => void
}
interface DestinationFormElementProps {
  destinationInput: string
  destinationCandidates: Candidate[]
  changeDestinationHandler: (candidate: string) => void
}

const DestinationFormElementText: React.FC<
  DestinationFormElementTextProps
> = props => {
  const {
    destinationInput,
    destinationCandidates,
    candidateIndex,
    inputFormRef,
    changeDestinationHandler,
    decisionHandler,
    keyDownHandler,
  } = props
  const { t } = useTranslation()

  const wrappedKeyDownDestinationHandler = (e: React.KeyboardEvent) => {
    if (
      !destinationCandidates[candidateIndex] ||
      !('ja' in destinationCandidates[candidateIndex]) ||
      !('en' in destinationCandidates[candidateIndex]) ||
      (i18n.language !== 'ja' && i18n.language !== 'en')
    ) {
      return
    }
    const candidate = destinationCandidates[candidateIndex][
      i18n.language
    ] as string

    if (eventEnterKeyDown(e)) {
      changeDestinationHandler(candidate)
      decisionHandler()
    } else if (eventEscapeKeyDown(e)) {
      decisionHandler()
    } else {
      keyDownHandler(e)
    }
  }

  return (
    <div className={styles.input}>
      <input
        className={styles.inputFormElement}
        id="destination"
        ref={inputFormRef}
        placeholder={t('searchform.destinationplaceholder')}
        value={destinationInput}
        onChange={e => changeDestinationHandler(e.target.value)}
        onKeyDown={wrappedKeyDownDestinationHandler}
      />
    </div>
  )
}

const DestinationFormElement: React.FC<DestinationFormElementProps> = props => {
  const {
    destinationInput,
    destinationCandidates,
    changeDestinationHandler,
  } = props

  const [modalState, setModalState] = useState(false)
  const [candidateIndex, setCandidateIndex] = useState(0)
  const inputFormRef = useRef<HTMLInputElement>(null)
  const choicedAreaRef = useRef<HTMLInputElement>(null)

  const clearDestinationHandler = (e: React.MouseEvent) => {
    changeDestinationHandler('')
    e.stopPropagation()
  }

  const startInputHandler = () => {
    setCandidateIndex(0)
    setModalState(true)
    setTimeout(() => {
      if (!inputFormRef || !inputFormRef.current) {
        return
      }
      inputFormRef.current.focus()
    }, 10)
  }

  const decisionHandler = () => {
    setModalState(false)
    setTimeout(() => {
      if (!choicedAreaRef || !choicedAreaRef.current) {
        return
      }
      choicedAreaRef.current.focus()
    }, 10)
  }

  const keyDownAnyHandler = (e: React.KeyboardEvent) => {
    if (eventEscapeKeyDown(e) || eventTabKeyDown(e)) {
      return
    }
    startInputHandler()
  }

  const keyDownHandler = (e: React.KeyboardEvent) => {
    if (
      !destinationCandidates[candidateIndex] ||
      !('ja' in destinationCandidates[candidateIndex]) ||
      !('en' in destinationCandidates[candidateIndex]) ||
      (i18n.language !== 'ja' && i18n.language !== 'en')
    ) {
      return
    }
    if (eventEnterKeyDown(e)) {
      e.preventDefault()
      if (destinationCandidates.length >= 1) {
        // 候補が1つ以上の時は Enter キーで確定
        changeDestinationHandler(
          destinationCandidates[candidateIndex][i18n.language],
        )
      } else {
        changeDestinationHandler(destinationInput)
      }
    }

    // 前の候補
    if (eventPrevKeyDown(e)) {
      e.preventDefault()
      setCandidateIndex(
        (candidateIndex - 1 + destinationCandidates.length) %
          destinationCandidates.length,
      )
    }

    // 次の候補
    if (eventNextKeyDown(e)) {
      e.preventDefault()
      setCandidateIndex((candidateIndex + 1) % destinationCandidates.length)
    }
  }

  const rootElement = document.getElementById('root')

  return (
    <div className={styles.container}>
      <div
        ref={choicedAreaRef}
        tabIndex={0}
        className={styles.choicedArea}
        onClick={startInputHandler}
        onKeyDown={keyDownAnyHandler}
      >
        {destinationInput !== '' ? (
          <div className={styles.choicedItem}>
            <p className={styles.choicedText}>{destinationInput}</p>
            <button
              tabIndex={-1}
              className={styles.choicedClearButton}
              onClick={clearDestinationHandler}
            >
              <i className="fas fa-times" />
            </button>
          </div>
        ) : (
          <p className={styles.choicedTextEmpty}>
            <Trans i18nKey="searchform.destinationplaceholder">
              destinationplaceholder
            </Trans>
          </p>
        )}
      </div>
      {modalState
        ? ReactDOM.createPortal(
            <div className={styles.modalBackground}>
              <div className={styles.modal}>
                <div className={styles.modalInner}>
                  <p className={styles.modalText}>
                    <Trans i18nKey="searchform.completion">completion</Trans>
                  </p>
                  <DestinationFormElementText
                    inputFormRef={inputFormRef}
                    decisionHandler={decisionHandler}
                    keyDownHandler={keyDownHandler}
                    candidateIndex={candidateIndex}
                    {...props}
                  />
                  <CandidatesList
                    candidates={destinationCandidates}
                    candidateIndex={candidateIndex}
                    setCandidateIndex={setCandidateIndex}
                    changeHandler={changeDestinationHandler}
                    decisionHandler={decisionHandler}
                  />
                </div>
                <span
                  className={styles.modalClosebutton}
                  onClick={() => setModalState(false)}
                >
                  <i className="fas fa-times"></i>
                </span>
              </div>
            </div>,
            rootElement as Element,
          )
        : null}
    </div>
  )
}

export default DestinationFormElement
