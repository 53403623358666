import React from 'react'
import { Trans } from 'react-i18next'
import styles from './Introduction.module.css'

import Help from './Help'

const Introduction: React.FC = () => {
  return (
    <div className={styles.introduction}>
      <div className={styles.introductionMessage}>
        <img
          className={styles.introductionMessageIcon}
          src="/resources/bus.png"
          width="100"
          alt=""
        />
        <h3 className={styles.introductionMessageText}>
          <span>
            <Trans i18nKey="introduction.description">description</Trans>
          </span>
          <Help i18nKey="help.introduction" />
        </h3>
      </div>
    </div>
  )
}

export default Introduction
