import React from 'react'
import { Trans } from 'react-i18next'
import i18n from 'i18next'
import styles from './CourseResultsMatched.module.css'

import { timetableURL } from '../helpers'
import Help from './Help'

interface CourseResultsMatchedProps {
  originInput: string
  destinationInput: string
  searchResults: Array<any>
}

const CourseResultsMatched: React.FC<CourseResultsMatchedProps> = props => {
  const { originInput, destinationInput, searchResults } = props
  const certainResults = searchResults
    .filter(a => !!a.certain)
    .sort((a, b) => parseInt(a.courseNo, 10) - parseInt(b.courseNo, 10))
  const uncertainResults = searchResults
    .filter(a => !a.certain)
    .sort((a, b) => parseInt(a.courseNo, 10) - parseInt(b.courseNo, 10))

  return (
    <>
      <div className={styles.matchedOverview}>
        {certainResults.length > 0 ? (
          <div className={styles.matchedOverviewItem}>
            <div className={styles.matchedOverviewCoursenumbers}>
              {certainResults.map(({ courseId, courseNo }, index) => (
                <a
                  href={`#detail-${courseId}`}
                  key={index}
                  className={styles.matchedOverviewCoursenumber}
                >
                  {courseNo}
                </a>
              ))}
            </div>
            <div className={styles.matchedOverviewMessage}>
              <img
                className={styles.matchedOverviewMessageIcon}
                src="/resources/bus.png"
                width="100"
                alt=""
              />
              <h3 className={styles.matchedOverviewMessageText}>
                <span>
                  <Trans i18nKey="courseresults.certaintext">certaintext</Trans>
                </span>
                <Help i18nKey="help.certain" />
              </h3>
            </div>
          </div>
        ) : (
          ''
        )}
        {uncertainResults.length > 0 ? (
          <div className={styles.matchedOverviewItem}>
            <div className={styles.matchedOverviewCoursenumbers}>
              {uncertainResults.map(({ courseId, courseNo }, index) => (
                <a
                  href={`#detail-${courseId}`}
                  key={index}
                  className={styles.matchedOverviewCoursenumber}
                >
                  {courseNo}
                </a>
              ))}
            </div>
            <div className={styles.matchedOverviewMessage}>
              <img
                className={styles.matchedOverviewMessageIcon}
                src="/resources/bus.png"
                width="100"
                alt=""
              />
              <h3 className={styles.matchedOverviewMessageText}>
                <span>
                  <Trans i18nKey="courseresults.uncertaintext">
                    uncertaintext
                  </Trans>
                </span>
                <Help i18nKey="help.uncertain" />
              </h3>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {searchResults.map((course, index) => (
        <div
          key={index}
          className={styles.matchedDetails}
          id={`detail-${course.courseId}`}
        >
          <h3 className={styles.matchedDetailsTitle}>
            <span className={styles.matchedDetailsTitleCoursenumber}>
              {course.courseNo}
            </span>
            <span className={styles.matchedDetailsTitleText}>
              {i18n.language === 'en' ? course.courseEn : course.courseJa}
            </span>
          </h3>
          <div className={styles.matchedDetailsList}>
            <div className={styles.matchedDetailsListitem}>
              <p>
                <Trans i18nKey="courseresults.aboarding">aboarding</Trans>
                <strong>{originInput}</strong>
              </p>
              <p>
                <Trans i18nKey="courseresults.alighting">alighting</Trans>
                <strong>{destinationInput}</strong>
              </p>
              <p>
                <Trans i18nKey="courseresults.totalstations">
                  totalstations
                </Trans>
                <strong>{course.totalStations}</strong>
              </p>
            </div>
            <div className={styles.matchedDetailsListitem}>
              <a
                href={timetableURL(course.paths[0].originStationId)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Trans i18nKey="courseresults.linkofficial">linkofficial</Trans>
              </a>
            </div>
            <div className={styles.matchedDetailsListitem}>
              {course.combinations.map(
                (
                  { routes, stations }: { routes: any[]; stations: any[] },
                  index: number,
                ) => (
                  <div key={index} className={styles.matchedDetailsRouteitem}>
                    <h4>
                      <Trans
                        i18nKey="courseresults.pathnumber"
                        count={index + 1}
                      >
                        pathnumber
                      </Trans>
                    </h4>
                    <p>
                      <Trans i18nKey="courseresults.totalstations">
                        totalstations
                      </Trans>
                      <strong>{stations.length - 1}</strong>
                    </p>
                    <p>
                      <Trans i18nKey="courseresults.stationlist">
                        stationlist
                      </Trans>
                    </p>
                    <ol className={styles.matchedDetailsRouteitemStations}>
                      {stations.map(({ id, ja, en }) => (
                        <li key={id}>{i18n.language === 'en' ? en : ja}</li>
                      ))}
                    </ol>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default CourseResultsMatched
