import React from 'react'
import { Route, Switch } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { useSSR } from 'react-i18next'
import queryString from 'query-string'

import './i18n'
import './App.css'
import Header from './components/Header'
import Tips from './components/Tips'
import PageContents from './components/PageContents'
import Courses from './components/Courses'
import Settings from './components/Settings'
import Hello from './components/Hello'
import Menu from './components/Menu'

const App = () => {
  const { location } = useReactRouter()
  const query = queryString.parse(location.search)
  const lang = 'lang' in query ? query.lang : 'ja'

  useSSR(null, lang)

  return (
    <Switch>
      <Route exact path={['/', '/settings']}>
        <div className="apps">
          <Header />
          <Tips />
          <PageContents>
            <Switch>
              <Route exact path="/" component={Courses} />
              <Route exact path="/settings" component={Settings} />
            </Switch>
          </PageContents>
          <Menu />
        </div>
      </Route>
      <Route exact path="/hello">
        <div className="pages">
          <Hello />
        </div>
      </Route>
    </Switch>
  )
}

export default App
