import React from 'react'
import i18n from 'i18next'
import { Trans } from 'react-i18next'

import styles from './CandidatesList.module.css'

interface Candidate {
  ja: string
  jaHiragana: string
  en: string
}
interface CandidatesListProps {
  candidates: Candidate[]
  candidateIndex: number
  setCandidateIndex: React.Dispatch<React.SetStateAction<number>>
  changeHandler: (input: string, isAuto: boolean) => void
  decisionHandler: (event: any) => void
}

const CandidatesList: React.FC<CandidatesListProps> = props => {
  const { candidates, candidateIndex, changeHandler, decisionHandler } = props

  const clickHandler = (e: any, candidate: Candidate) => {
    if (i18n.language !== 'ja' && i18n.language !== 'en') {
      return
    }
    changeHandler(candidate[i18n.language], false)
    decisionHandler(e)
  }

  return (
    <div className={styles.candidates}>
      {candidates.length > 0 ? (
        <>
          <p className={styles.candidatesText}>
            <Trans i18nKey="searchform.candidatetext" count={candidates.length}>
              foundtext
            </Trans>
          </p>
          <ul className={styles.candidatesList}>
            {candidates.map((candidate, index) => (
              <li
                key={index}
                className={[
                  styles.candidatesListItem,
                  index === candidateIndex
                    ? styles.candidatesListItemActive
                    : null,
                ].join(' ')}
                onClick={e => clickHandler(e, candidate)}
              >
                <span className={styles.candidatesSubText}>
                  {i18n.language === 'en'
                    ? `${candidate.ja} - ${candidate.jaHiragana}`
                    : `${candidate.jaHiragana} - ${candidate.en}`}
                </span>
                <span className={styles.candidatesMainText}>
                  {i18n.language === 'en' ? candidate.en : candidate.ja}
                </span>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  )
}

export default CandidatesList
