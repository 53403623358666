import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'

import i18n from 'i18next'
import CandidatesList from './CandidatesList'
import styles from './OriginFormElement.module.css'

import {
  eventTabKeyDown,
  eventEnterKeyDown,
  eventPrevKeyDown,
  eventNextKeyDown,
  eventEscapeKeyDown,
} from '../helpers'

const OriginFormElementText = (props: any) => {
  const {
    originInput,
    originCandidates,
    candidateIndex,
    inputFormRef,
    changeOriginHandler,
    decisionHandler,
    keyDownHandler,
  } = props
  const { t } = useTranslation()

  const wrappedKeyDownOriginHandler = (event: React.KeyboardEvent) => {
    if (eventEnterKeyDown(event)) {
      if (originCandidates.length > 0) {
        changeOriginHandler(originCandidates[candidateIndex][i18n.language])
      } else {
        changeOriginHandler(originInput)
      }
      decisionHandler()
    } else if (eventEscapeKeyDown(event)) {
      decisionHandler()
    } else {
      keyDownHandler(event)
    }
  }

  return (
    <div className={styles.input}>
      <input
        className={styles.inputFormElement}
        id="origin"
        ref={inputFormRef}
        placeholder={t('searchform.originplaceholder')}
        value={originInput}
        onChange={e => changeOriginHandler(e.target.value)}
        onKeyDown={wrappedKeyDownOriginHandler}
      />
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OriginFormElementMethods = (props: any) => {
  const { selectedMethods, setSelectedMethods } = props

  return (
    <div className={styles.choices}>
      <p className={styles.choicesPrefixText}>出発地のバス停の名前が・・・</p>
      <div className={styles.choicesInner}>
        <label
          htmlFor="originmethod1"
          className={[
            styles.choicesLabel,
            selectedMethods === 'fixed' ? styles.choicesLabelActive : '',
          ].join(' ')}
        >
          <input
            type="radio"
            name="originmethod"
            id="originmethod1"
            value="fixed"
            checked={selectedMethods === 'fixed'}
            className={styles.choicesRadiobutton}
            onChange={() => {
              setSelectedMethods('fixed')
            }}
          />
          <span className={styles.choicesLabelText}>分かる！</span>
        </label>
        <label
          htmlFor="originmethod2"
          className={[
            styles.choicesLabel,
            selectedMethods === 'flexible' ? styles.choicesLabelActive : '',
          ].join(' ')}
        >
          <input
            type="radio"
            name="originmethod"
            id="originmethod2"
            value="flexible"
            checked={selectedMethods === 'flexible'}
            className={styles.choicesRadiobutton}
            onChange={() => {
              setSelectedMethods('flexible')
            }}
            disabled
          />
          <span className={styles.choicesLabelText}>分からない...</span>
        </label>
      </div>
    </div>
  )
}

const OriginFormElement = (props: any) => {
  const { originInput, originCandidates, changeOriginHandler } = props

  const [modalState, setModalState] = useState(false)
  const [candidateIndex, setCandidateIndex] = useState(0)
  /* eslint-disable-next-line no-unused-vars */
  const inputFormRef = useRef<HTMLInputElement>()
  const choicedAreaRef = useRef<HTMLDivElement>(null)

  const clearOriginHandler = (e: React.MouseEvent) => {
    changeOriginHandler('')
    e.stopPropagation()
  }

  const startInputHandler = () => {
    setCandidateIndex(0)
    setModalState(true)
    setTimeout(() => {
      inputFormRef.current && inputFormRef.current.focus()
    }, 10)
  }

  const decisionHandler = () => {
    setModalState(false)
    setTimeout(() => {
      choicedAreaRef.current && choicedAreaRef.current.focus()
    }, 10)
  }

  const keyDownAnyHandler = (e: React.KeyboardEvent) => {
    if (eventEscapeKeyDown(e) || eventTabKeyDown(e)) {
      return
    }
    startInputHandler()
  }

  const keyDownHandler = (e: React.KeyboardEvent) => {
    if (eventEnterKeyDown(e)) {
      e.preventDefault()
      if (originCandidates.length >= 1) {
        // 候補が1つ以上の時は Enter キーで確定
        changeOriginHandler(originCandidates[candidateIndex][i18n.language])
      } else {
        changeOriginHandler(originInput)
      }
    }

    // 前の候補
    if (eventPrevKeyDown(e)) {
      e.preventDefault()
      setCandidateIndex(
        (candidateIndex - 1 + originCandidates.length) %
          originCandidates.length,
      )
    }

    // 次の候補
    if (eventNextKeyDown(e)) {
      e.preventDefault()
      setCandidateIndex((candidateIndex + 1) % originCandidates.length)
    }
  }

  const rootElement = document.getElementById('root') as Element

  return (
    <div className={styles.container}>
      <div
        ref={choicedAreaRef}
        tabIndex={0}
        className={styles.choicedArea}
        onClick={startInputHandler}
        onKeyDown={keyDownAnyHandler}
      >
        {originInput !== '' ? (
          <div className={styles.choicedItem}>
            <p className={styles.choicedText}>{originInput}</p>
            <button
              tabIndex={-1}
              className={styles.choicedClearButton}
              onClick={clearOriginHandler}
            >
              <i className="fas fa-times" />
            </button>
          </div>
        ) : (
          <p className={styles.choicedTextEmpty}>
            <Trans i18nKey="searchform.originplaceholder">
              originplaceholder
            </Trans>
          </p>
        )}
      </div>
      {modalState
        ? ReactDOM.createPortal(
            <div className={styles.modalBackground}>
              <div className={styles.modal}>
                <div className={styles.modalInner}>
                  <p className={styles.modalText}>
                    <Trans i18nKey="searchform.completion">completion</Trans>
                  </p>
                  <OriginFormElementText
                    inputFormRef={inputFormRef}
                    decisionHandler={decisionHandler}
                    keyDownHandler={keyDownHandler}
                    candidateIndex={candidateIndex}
                    {...props}
                  />
                  <CandidatesList
                    candidates={originCandidates}
                    candidateIndex={candidateIndex}
                    setCandidateIndex={setCandidateIndex}
                    changeHandler={changeOriginHandler}
                    decisionHandler={decisionHandler}
                  />
                </div>
                <span
                  className={styles.modalClosebutton}
                  onClick={() => setModalState(false)}
                >
                  <i className="fas fa-times"></i>
                </span>
              </div>
            </div>,
            rootElement,
          )
        : null}
    </div>
  )
}

export default OriginFormElement
